import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {IDbRooms, IDbSystemInfo} from './AngularServices/AngularServices';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {IEventLogsService} from './EventsLogService';
import {UrlValidationService} from '@techsee/techsee-client-services/lib/services/UrlValidationService';
import {DomInteractionService} from '@techsee/techsee-client-services/lib/services/DomInteractionService';
import {getAppTracer} from '../../app.tracer';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';
import TsUtils from '@techsee/techsee-common/lib/utils';

const trace = getAppTracer('Redirection_Service');

interface IExternalDashboardParams {
    roomId?: string;
    shortId?: string;
}

interface IUrlParams {
    customerRef?: string;
    phoneNumber?: string;
    countryCode?: string;
    initialState?: string;
    roomId?: string;
    guid?: string;
    roomCode?: string;
    audio?: string;
    room?: string;
    observe?: string;
    mini?: string;
    emb?: string;
    ott?: string;
    tablet?: string;
    sw?: string;
    prg?: string;
    startDate?: string;
    endDate?: string;
}

export function addParamsToUrl(paramToAdd: IUrlParams) {
    const params = [];

    if (paramToAdd.room) {
        params.push(`room=${paramToAdd.room}`);
    }

    if (paramToAdd.customerRef) {
        params.push(`r=${paramToAdd.customerRef}`);
    }

    if (paramToAdd.phoneNumber) {
        params.push(`p=${paramToAdd.phoneNumber}`);
    }

    if (paramToAdd.initialState) {
        params.push(`is=${paramToAdd.initialState}`);
    }

    if (paramToAdd.roomId) {
        params.push(`roomId=${paramToAdd.roomId}`);
    }

    if (paramToAdd.guid) {
        params.push(`g=${paramToAdd.guid}`);
    }

    if (paramToAdd.ott) {
        params.push(`ott=${paramToAdd.ott}`);
    }

    if (paramToAdd.roomCode) {
        params.push(`roomCode=${paramToAdd.roomCode}`);
    }

    if (paramToAdd.audio) {
        params.push(`audio=${paramToAdd.audio}`);
    }

    if (paramToAdd.observe) {
        params.push(`observe=${paramToAdd.observe}`);
    }

    if (paramToAdd.mini) {
        params.push(`mini=${paramToAdd.mini}`);
    }

    if (paramToAdd.emb) {
        params.push(`emb=${paramToAdd.emb}`);
    }

    if (paramToAdd.sw) {
        params.push(`sw=${paramToAdd.sw}`);
    }

    if (paramToAdd.prg) {
        params.push(`prg=${paramToAdd.prg}`);
    }

    if (paramToAdd.startDate) {
        params.push(`startDate=${paramToAdd.startDate}`);
    }

    if (paramToAdd.endDate) {
        params.push(`startDate=${paramToAdd.endDate}`);
    }

    return params.length ? `?${params.join('&')}` : '';
}

export interface IRedirectionService {
    goToRootUrl(): void;

    goToInvite(): void;

    goToAdmin(): Promise<void>;

    goToHistoryByCustomerRef(customerRef: string): void;

    goToClientSide(url: string): void;

    goToHistoryByPhoneNumber(phoneNumber: string): void;

    goToDashboard(params: IUrlParams, loadMultipartyDashboard: boolean, sessionRoomId: string): void;

    goToExternalDashboard(params: IExternalDashboardParams): void;

    goToDashboardEntry(params: IUrlParams): void;

    goToUrl(url: string): void;

    goToVJHistory(): void;

    goToWifiAnalyzerHistory(): void;

    goToPortal(): void;
}

export class RedirectionService implements IRedirectionService {
    private readonly _window: Window;

    private readonly _systemInfoService: IDbSystemInfo;

    private readonly _browserUtilsService: IBrowserUtilsService;

    private readonly _eventsLogService: IEventLogsService;

    private readonly _roomService: IDbRooms;

    constructor(
        window: Window,
        systemInfoService: IDbSystemInfo,
        browserUtilsService: IBrowserUtilsService,
        eventsLogService: IEventLogsService,
        roomService: IDbRooms
    ) {
        this._window = window;
        this._systemInfoService = systemInfoService;
        this._browserUtilsService = browserUtilsService;
        this._eventsLogService = eventsLogService;
        this._roomService = roomService;
    }

    async validateBundle(roomId: string): Promise<void> {
        if (VERIFY_BUNDLE.DISABLE_BUNDLE_VALIDITY) {
            return Promise.resolve();
        }

        const bundleSrc = await DomInteractionService.getScriptURL('dist/app.bundle');
        const requestTimeout = VERIFY_BUNDLE.REQUEST_TIMEOUT;
        const failureReportingTimeout = VERIFY_BUNDLE.FAILURE_REPORTING_TIMEOUT;

        if (!bundleSrc) {
            const tracePromise = () =>
                trace.error(
                    `Failed getting the bundle url from the DOM (redirection phase):
                        ${JSON.stringify({
                            logType: LOG_EVENTS.bundleLinkNotFound.type,
                            roomId
                        })}`,
                    'Failed getting the bundle url from the DOM'
                );

            await PromiseUtilsService.startPromiseWithTimeout(tracePromise, failureReportingTimeout);

            return;
        }

        try {
            return await UrlValidationService.testUrlExistance(bundleSrc, requestTimeout);
        } catch (err: any) {
            if (err && err.response && err.response.status === 404) {
                const eventLogPromise = () =>
                    this._eventsLogService.error({
                        logType: LOG_EVENTS.bundleLinkNotFound.type,
                        roomId,
                        meta: {
                            bundlePath: bundleSrc,
                            errorType: 'Loading the bundle results in 404',
                            err
                        }
                    });

                await PromiseUtilsService.startPromiseWithTimeout(eventLogPromise, failureReportingTimeout);

                return Promise.resolve();
            }
        }
    }

    goToRootUrl() {
        this._browserUtilsService.redirect('/');
    }

    goToUrl(url: string) {
        this._browserUtilsService.redirect(url);
    }

    goToVJHistory() {
        this._browserUtilsService.redirect('/#/vjHistory');
    }

    goToWifiAnalyzerHistory() {
        this._browserUtilsService.redirect('/#/wifiAnalyzerHistory');
    }

    goToPortal() {
        // @ts-ignore
        const portalURL = TsUtils.replaceUrlDomain(PORTAL_URL, window.location.hostname);

        this._browserUtilsService.redirect(`${portalURL}/portal`);
    }

    goToInvite() {
        const customerRef = this._browserUtilsService.getParamValue('r');
        const phoneNumber = this._browserUtilsService.getParamValue('p');
        const countryCode = this._browserUtilsService.getParamValue('c');
        const initialState = this._browserUtilsService.getParamValue('is');
        const roomId = this._browserUtilsService.getParamValue('roomId');
        const guid = this._browserUtilsService.getParamValue('g');
        const roomCode = this._browserUtilsService.getParamValue('roomCode');
        const audio = this._browserUtilsService.getParamValue('audio');
        const observe = this._browserUtilsService.getParamValue('observe');
        const mini = this._browserUtilsService.getParamValue('mini');
        const emb = this._browserUtilsService.getParamValue('emb');
        const sw = this._browserUtilsService.getParamValue('sw');
        const ott = this._browserUtilsService.getParamValue('ott');
        const tablet = this._browserUtilsService.getParamValue('tablet');
        const prg = this._browserUtilsService.getParamValue('prg');
        const startDate = this._browserUtilsService.getParamValue('startDate');
        const endDate = this._browserUtilsService.getParamValue('endDate');

        const allParams: IUrlParams = {
            customerRef,
            phoneNumber,
            countryCode,
            roomId,
            guid,
            roomCode,
            audio,
            initialState,
            observe,
            emb,
            mini,
            sw,
            ott,
            tablet,
            prg,
            startDate,
            endDate
        };

        this._browserUtilsService.redirect(`/#/invite${addParamsToUrl(allParams)}`);
    }

    async goToAdmin() {
        const response = await this._systemInfoService.adminUrl({
            params: {
                origin: this._browserUtilsService.getCurrentOrigin()
            }
        });

        this._browserUtilsService.redirect(response.data.adminUrl);
    }

    goToHistoryByCustomerRef(customerRef: string) {
        this._browserUtilsService.redirect(`/#/history?customerId=${this.encodeURI(customerRef)}`);
    }

    goToHistoryByPhoneNumber(phoneNumber: string) {
        this._browserUtilsService.redirect(`/#/history?customerNumber=${this.encodeURI(phoneNumber)}`);
    }

    goToDashboard(params: IUrlParams, loadMultipartyDashboard: boolean, sessionRoomId: string) {
        const dashboardPrefix = loadMultipartyDashboard ? 'multipartyDashboard' : 'dashboard';

        this.validateBundle(sessionRoomId).finally(() => {
            const tracePromise = () =>
                trace.info(`Moving to Dashboard! url: /#/${dashboardPrefix}${addParamsToUrl(params)}`);

            return PromiseUtilsService.startPromiseWithTimeout(tracePromise, 3000).finally(() =>
                this._browserUtilsService.redirect(`/#/${dashboardPrefix}${addParamsToUrl(params)}`)
            );
        });
    }

    async goToExternalDashboard(params: IExternalDashboardParams) {
        const url = await this._roomService.dashboardV3Url({params: {...params}});

        this._browserUtilsService.redirect(url.data);
    }

    goToDashboardEntry(params: IUrlParams) {
        this._browserUtilsService.redirect(`/#/dashboard/entry${addParamsToUrl(params)}`);
    }

    goToClientSide(url: string) {
        this._browserUtilsService.redirect(url);
    }

    private encodeURI(uri: string) {
        try {
            return encodeURIComponent(uri);
        } catch (error) {
            return uri;
        }
    }
}
