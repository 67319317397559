/* eslint-disable indent */
import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITranslate} from '../../services/LocalizationService';
import {VJForm} from '../vj-form/form';
import {WifiAnalyzerFilterController} from './controller';

export interface IWifiAnalyzerFilter {
    ctrl: WifiAnalyzerFilterController;
    translate: ITranslate;
    onSubmit?: () => void;
}

@observer
export class WifiAnalyzerFilter extends TechseeBaseComponent<IWifiAnalyzerFilter> {
    renderInternal() {
        const {ctrl, translate} = this.props;

        return (
            <VJForm
                ctrl={ctrl.formController}
                translate={translate}
                formErrorText={'INVITE.VIEW.HISTORY_SEARCH.NO_RESULTS'}
                labelText={'INVITE.VIEW.VJ_HISTORY_SEARCH.LABEL_HISTORY'}
                buttonText={'INVITE.VIEW.SEARCH'}
            />
        );
    }
}
