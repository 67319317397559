import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IWifiAnalyzerResults} from '../controller';
import {NATIVE_PLATFORM_TYPE} from '@techsee/techsee-common/lib/constants/room.constants';
import {WifiAnalyzerMode} from '../component';

interface ChannelsTabProps {
    platformType: string;
    channelAnalysis: IWifiAnalyzerResults['channelAnalysis'];
    isScanning: boolean;
    onStartScan: () => void;
    mode: WifiAnalyzerMode;
}

export class ChannelsTab extends TechseeBaseComponent<ChannelsTabProps> {
    currentStatus(points: number) {
        if (points <= 2) {
            return '#40DE7A';
        } else if (points > 2 && points <= 5) {
            return '#DDE20D';
        } else if (points > 5 && points <= 10) {
            return '#FF983D';
        }

        return '#E11D48';
    }

    renderInfoIcon() {
        return (
            <svg style={{fontSize: '16px'}} width='14' height='14' viewBox='0 0 14 14' fill='currentColor'>
                <path
                    d='M6.33337 3.66659H7.66671V4.99992H6.33337V3.66659ZM6.33337 6.33325H7.66671V10.3333H6.33337V6.33325ZM7.00004 0.333252C3.32004 0.333252 0.333374 3.31992 0.333374 6.99992C0.333374 10.6799 3.32004 13.6666 7.00004 13.6666C10.68 13.6666 13.6667 10.6799 13.6667 6.99992C13.6667 3.31992 10.68 0.333252 7.00004 0.333252ZM7.00004 12.3333C4.06004 12.3333 1.66671 9.93992 1.66671 6.99992C1.66671 4.05992 4.06004 1.66659 7.00004 1.66659C9.94004 1.66659 12.3334 4.05992 12.3334 6.99992C12.3334 9.93992 9.94004 12.3333 7.00004 12.3333Z'
                    fill='currentColor'
                />
            </svg>
        );
    }

    renderTetheringIcon() {
        return (
            <svg width='16' height='16' viewBox='0 0 16 16' fill='currentColor'>
                <path
                    d='M8.00004 7.33333C7.26671 7.33333 6.66671 7.93333 6.66671 8.66667C6.66671 9.4 7.26671 10 8.00004 10C8.73337 10 9.33337 9.4 9.33337 8.66667C9.33337 7.93333 8.73337 7.33333 8.00004 7.33333ZM12 8.66667C12 6.46 10.2067 4.66667 8.00004 4.66667C5.79337 4.66667 4.00004 6.46 4.00004 8.66667C4.00004 10.1467 4.80671 11.4333 6.00004 12.1267L6.66671 10.9667C5.87337 10.5 5.33337 9.65333 5.33337 8.66667C5.33337 7.19333 6.52671 6 8.00004 6C9.47337 6 10.6667 7.19333 10.6667 8.66667C10.6667 9.65333 10.1267 10.5 9.33337 10.9667L10 12.1267C11.1934 11.4333 12 10.1467 12 8.66667ZM8.00004 2C4.32004 2 1.33337 4.98667 1.33337 8.66667C1.33337 11.1333 2.67337 13.28 4.66004 14.4333L5.32671 13.28C3.74004 12.3533 2.66671 10.64 2.66671 8.66667C2.66671 5.72 5.05337 3.33333 8.00004 3.33333C10.9467 3.33333 13.3334 5.72 13.3334 8.66667C13.3334 10.64 12.26 12.3533 10.6667 13.28L11.3334 14.4333C13.3267 13.28 14.6667 11.1333 14.6667 8.66667C14.6667 4.98667 11.68 2 8.00004 2Z'
                    fill='currentColor'
                />
            </svg>
        );
    }

    renderInternal() {
        const {platformType, channelAnalysis, isScanning, mode} = this.props;
        const isAndroid = platformType === NATIVE_PLATFORM_TYPE.android;
        const hasAnalysis = channelAnalysis && channelAnalysis.length > 0;
        const hasAdvice = false;

        if (mode === WifiAnalyzerMode.History && !hasAnalysis) {
            return (
                <div className='wifi-analyzer-channels-tab-scan-area'>
                    <span className='wifi-analyzer-channels-tab-scan-title'>No Data</span>
                </div>
            );
        }

        if (!isAndroid && !hasAnalysis) {
            return (
                <div className='wifi-analyzer-channels-tab-scan-area'>
                    <img src='/img/ios-network-scan.png' className='wifi-analyzer-channels-tab-image'></img>
                    <span className='wifi-analyzer-channels-tab-scan-title'>
                        Make sure client is on the Channel usage
                        <br />
                        screen, then click &apos;Scan Networks&apos;.
                    </span>
                    <button
                        className='wifi-analyzer-channels-tab-scan-button'
                        disabled={isScanning}
                        onClick={() => this.props.onStartScan()}>
                        {isScanning ? 'Scanning...' : 'Scan networks'}
                    </button>
                </div>
            );
        }

        return (
            <div className='wifi-analyzer-content'>
                {hasAdvice && (
                    <div className='wifi-analyzer-channels-tab-header'>
                        {this.renderInfoIcon()}
                        <span style={{marginLeft: '10px'}}>We advise you to use the following networks:</span>
                        <ul>
                            <li>
                                <b>5Ghz</b>
                                <span> Channels: 1, 5, 6</span>
                            </li>
                            <li>
                                <b>2.4Ghz</b>
                                <span> Channels: 11, 15</span>
                            </li>
                        </ul>
                    </div>
                )}
                <div className='wifi-analyzer-channels-tab'>
                    {channelAnalysis &&
                        channelAnalysis.map((item, idx) => (
                            <div key={idx} className='wifi-analyzer-channels-tab-item'>
                                <div>
                                    <b>CH {item.channel}</b>
                                    <span className='gray-badge'>{item.freq} GHz</span>
                                </div>
                                <div className='wifi-analyzer-channels-tab-item-column'>
                                    <span>
                                        {this.renderTetheringIcon()} Access Points <b>{item.totalAP}</b>
                                    </span>
                                    <div className={'wifi-analyzer-channels-tab-item-progress'}>
                                        <div
                                            className='bar'
                                            style={{backgroundColor: `${this.currentStatus(item.totalAP)}`}}
                                        />
                                        <div
                                            className='bar opaque'
                                            style={{backgroundColor: `${this.currentStatus(item.totalAP)}`}}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}
